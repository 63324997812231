import React, { useState } from 'react'
import { Row, Col, Avatar, Dropdown, Button, Menu, Badge } from 'antd'
import {
	DownOutlined,
	BellOutlined,
	QuestionCircleOutlined,
	SolutionOutlined,
	FileProtectOutlined,
	ReadOutlined,
	BulbOutlined,
	LockOutlined,
	SettingOutlined,
	LogoutOutlined,
	ProfileOutlined
} from '@ant-design/icons'

import MessageList from './MessageList'

const menu = (
	<Menu
		items={[
			{
				key: '1',
				label: (
					<a target="_blank" href="/">
						Profile
          </a>
				),
				icon: <ProfileOutlined style={{ fontSize: 16 }} />,
				disabled: false,
			},
			{
				key: '2',
				label: (
					<a target="_blank" href="/">
						Security
          </a>
				),
				icon: <LockOutlined style={{ fontSize: 16 }} />,
				disabled: false,
			},
			{
				key: '3',
				label: (
					<a target="_blank" href="/">
						Settings
          </a>
				),
				icon: <SettingOutlined style={{ fontSize: 16 }} />,
				disabled: false,
			},
			{
				type: 'divider',
			},
			{
				key: '4',
				danger: false,
				label: (
					<a target="_blank" href="/">
						Logout
          </a>
				),
				icon: <LogoutOutlined style={{ fontSize: 16 }} />,
				disabled: false,
			},
		]}
	/>
)
const message = (
	<Menu
		items={[
			{
				key: '1',
				label: (
					<a target="_blank" href="/" style={{ maxWidth: 200, clear: 'both', overflow: 'hidden', display: 'block' }}>
						<Avatar src={MessageList[1].photo} size={20} />
						<span style={{ paddingLeft: 8, color: '#595959', fontSize: 13, lineHeight: 1.25, margin: '-22px 0 -5px 20px', display: 'inline-block' }}>{MessageList[1].message}</span>
					</a>
				),
				disabled: false,
			},
			{
				key: '2',
				label: (
					<a target="_blank" href="/" style={{ maxWidth: 200, clear: 'both', overflow: 'hidden', display: 'block' }}>
						<Avatar src={MessageList[2].photo} size={20} />
						<span style={{ paddingLeft: 8, color: '#595959', fontSize: 13, lineHeight: 1.25, margin: '-22px 0 -5px 20px', display: 'inline-block' }}>{MessageList[2].message}</span>
					</a>
				),
				disabled: false,
			},
			{
				key: '3',
				label: (
					<a target="_blank" href="/" style={{ maxWidth: 200, clear: 'both', overflow: 'hidden', display: 'block' }}>
						<Avatar src={MessageList[3].photo} size={20} />
						<span style={{ paddingLeft: 8, color: '#595959', fontSize: 13, lineHeight: 1.25, margin: '-22px 0 -5px 20px', display: 'inline-block' }}>{MessageList[3].message}</span>
					</a>
				),
				disabled: false,
			},
			{
				key: '4',
				label: (
					<a target="_blank" href="/" style={{ maxWidth: 200, clear: 'both', overflow: 'hidden', display: 'block' }}>
						<Avatar src={MessageList[4].photo} size={20} />
						<span style={{ paddingLeft: 8, color: '#595959', fontSize: 13, lineHeight: 1.25, margin: '-22px 0 -5px 20px', display: 'inline-block' }}>{MessageList[4].message}</span>
					</a>
				),
				disabled: false,
			},
			{
				key: '5',
				label: (
					<a target="_blank" href="/" style={{ maxWidth: 200, clear: 'both', overflow: 'hidden', display: 'block' }}>
						<Avatar src={MessageList[5].photo} size={20} />
						<span style={{ paddingLeft: 8, color: '#595959', fontSize: 13, lineHeight: 1.25, margin: '-22px 0 -5px 20px', display: 'inline-block' }}>{MessageList[5].message}</span>
					</a>
				),
				disabled: false,
			},
		]}
	/>
)
const help = (
	<Menu
		items={[
			{
				key: '1',
				label: (
					<a target="_blank" href="/">
						Support
          </a>
				),
				icon: <ReadOutlined style={{ fontSize: 16 }} />,
				disabled: false,
			},
			{
				key: '2',
				label: (
					<a target="_blank" href="/">
						F.A.Q
          </a>
				),
				icon: <BulbOutlined style={{ fontSize: 16 }} />,
				disabled: false,
			},
			{
				key: '3',
				label: (
					<a target="_blank" href="/">
						Privacy Policy
          </a>
				),
				icon: <FileProtectOutlined style={{ fontSize: 16 }} />,
				disabled: false,
			},
			{
				key: '4',
				danger: false,
				label: (
					<a target="_blank" href="/">
						Terms of Use
          </a>
				),
				icon: <SolutionOutlined style={{ fontSize: 16 }} />,
				disabled: false,
			},
		]}
	/>
)

const Author = (props) => {


	const [count, setCount] = useState(5)
	const [showBadge, setShowBadge] = useState('block')

	return (
		<Row justify="end">
			<Col style={{ marginTop: 3 }}>
				<Dropdown overlay={help} placement="bottomRight">
					<Button onClick={(e) => e.preventDefault()} style={{ border: 'none', boxShadow: 'none', background: 'none' }}>
						<QuestionCircleOutlined style={{ fontSize: 22 }} />
					</Button>
				</Dropdown>
			</Col>
			<Col style={{ paddingRight: 20, marginTop: 3 }}>
				<Dropdown overlay={message} placement="bottomRight">
					<Button
						onClick={(e) => {
							e.preventDefault()
							setCount(0)
							setShowBadge('none')
						}}
						style={{ border: 'none', boxShadow: 'none', background: 'none' }}
					>
						<Badge count={count} style={{ display: showBadge }}>
							<BellOutlined style={{ fontSize: 22 }} />
						</Badge>
					</Button>
				</Dropdown>
			</Col>
			<Col style={{ marginTop: -2 }}>
				<Dropdown overlay={menu} placement="bottomRight">
					<Button onClick={(e) => e.preventDefault()} style={{ margin: 0, padding: 0, border: 'none', boxShadow: 'none', background: 'none' }}>
						<Avatar src={props.photo} size={32} />
						<span style={{ paddingLeft: 8, color: '#595959', fontWeight: 500 }}>{props.name}</span>
						<DownOutlined />
					</Button>
				</Dropdown>
			</Col>
		</Row>
	)
}
export default Author