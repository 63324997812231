import React from 'react'
import { Row, Col, Space, Card, Typography, Tooltip, Button } from 'antd'
import { ClockCircleOutlined, CalendarOutlined, HistoryOutlined, UserAddOutlined, DeleteOutlined, PlayCircleOutlined } from '@ant-design/icons'

const { Text } = Typography
const { Title } = Typography

const CardMeet = (props) => {
	return (
		<Card
			style={{ marginBottom: 16 }}
			size="small"
			actions={[
				<Tooltip color={'#73D13D'} title={<Text style={{ color: "#fff" }}>{props.count} user</Text>}>
					<Button type="text" icon={<PlayCircleOutlined style={{ color: "#73D13D" }} />} />
				</Tooltip>,
				<Tooltip title="Calendar">
					<Button type="text" icon={<CalendarOutlined />} />
				</Tooltip>,
				<Tooltip title="Reschedule">
					<Button type="text" icon={<HistoryOutlined />} />
				</Tooltip>,
				<Tooltip title="Add People">
					<Button type="text" icon={<UserAddOutlined />} />
				</Tooltip>,
				<Tooltip title="Remove">
					<Button type="text" icon={<DeleteOutlined />} />
				</Tooltip>
			]}
		>
			<Row
				align="center"
				justify="space-between"
				style={{ padding: '0 10px', borderBottom: '1px solid #f6f6f6' }}
			>
				<Col>
					<Title level={4}>{props.title}</Title>
				</Col>
				<Col style={{ paddingTop: 3 }}>
					<Space justify="space-between">
						<ClockCircleOutlined />
						<Text style={{ fontSize: 13 }}>{props.start} - {props.stop}</Text>
					</Space>
				</Col>
			</Row>
			<Row>
				<Col style={{ padding: 10 }}>
					<Text style={{ fontSize: 14 }}>{props.desc}</Text>
				</Col>
			</Row>
			<Row>
				<Col style={{ padding: 10 }}>
					<Space>
						<CalendarOutlined />
						<Text style={{ fontSize: 13 }}>{props.day}.{props.month}.{props.year}</Text>
					</Space>
				</Col>
			</Row>
		</Card>
	)
}
export default CardMeet