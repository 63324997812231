import React from 'react'
import { Layout, Menu, Row, Col, Timeline, Card, Tag, Space, Calendar, Typography } from 'antd'

import {
	DesktopOutlined,
	PieChartOutlined,
	FileOutlined,
	TeamOutlined,
	UserOutlined,
} from '@ant-design/icons'
import './App.css'
import CardMeet from './components/CardMeet'
import CardTitle from './components/CardTitle'
import Author from './components/Author'
import UserList from './components/UserList'
import MeetList from './components/MeetList'
import HoursList from './components/HoursList'
import CardTitleList from './components/CardTitleList'
import CardTitleUser from './components/CardTitleUser'
import CardTitleVideo from './components/CardTitleVideo'
import CheckTime from './components/CheckTime'
import SelectMeet from './components/SelectMeet'
import Topics from './components/Topics'
import Avatars from './components/Avatars'

const { Header, Content, Footer, Sider } = Layout
function getItem(label, key, icon, children) {
	return {
		key,
		icon,
		children,
		label,
	}
}

const { Text } = Typography

const items = [
	getItem('Option 1', '1', <PieChartOutlined />),
	getItem('Option 2', '2', <DesktopOutlined />),
	getItem('User', 'sub1', <UserOutlined />, [
		getItem('Tom', '3'),
		getItem('Bill', '4'),
		getItem('Alex', '5'),
	]),
	getItem('Team', 'sub2', <TeamOutlined />, [getItem('Team 1', '6'), getItem('Team 2', '8')]),
	getItem('Files', '9', <FileOutlined />),
]

class App extends React.Component {
	state = {
		collapsed: true,
	}
	onCollapse = (collapsed) => {
		console.log(collapsed)
		this.setState({
			collapsed,
		})
	}


	render() {

		const { collapsed } = this.state

		var now = new Date()
		var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
		const currentDate = days[now.getDay()] + ', ' + now.getDate() + ' ' + now.toLocaleString('en-GB', { month: 'long' })

		return (
			<Layout
				style={{
					minHeight: '100vh',
				}}
			>
				<Sider theme="light" collapsible collapsed={collapsed} onCollapse={this.onCollapse} className="site-layout-background">
					<div className="logo" style={{ marginTop: 12, marginBottom: -4 }}></div>
					<Menu theme="light" defaultSelectedKeys={['1']} mode="inline" items={items} style={{ marginTop: 11, border: 'none' }} />
				</Sider>
				<Layout className="site-layout-background">
					<Header className="site-layout-background" style={{ borderBottom: '1px solid #f6f6f6' }}>
						<Author
							photo={UserList[0].photo}
							name={UserList[0].name}
						/>
					</Header>
					<Content style={{ minHeight: '78vh', borderLeft: '1px solid #f6f6f6' }}>
						<div
							className="site-layout-background"
							style={{
								padding: 24,
								minHeight: 360,
							}}
						>
							<Row justify="space-between">
								<Col span={6}>
									<CardTitle
										background="#7879f1"
										photo={UserList[0].photo}
										name={UserList[0].name}
										position={UserList[0].position}
									/>
									<CardMeet
										title={MeetList[0].title}
										desc={MeetList[0].desc}
										start={MeetList[0].start}
										stop={MeetList[0].stop}
										month={MeetList[0].month}
										day={MeetList[0].day}
										year={MeetList[0].year}
										count={MeetList[0].count}
									/>
									<CardTitle
										background="#9254DE"
										photo={UserList[0].photo}
										name={UserList[0].name}
										position={UserList[0].position}
									/>
									<CardMeet
										title={MeetList[1].title}
										desc={MeetList[1].desc}
										start={MeetList[1].start}
										stop={MeetList[1].stop}
										month={MeetList[1].month}
										day={MeetList[1].day}
										year={MeetList[1].year}
										count={MeetList[1].count}
									/>
									<CardTitle
										background="#40A9FF"
										photo={UserList[0].photo}
										name={UserList[0].name}
										position={UserList[0].position}
									/>
									<CardMeet
										title={MeetList[2].title}
										desc={MeetList[2].desc}
										start={MeetList[2].start}
										stop={MeetList[2].stop}
										month={MeetList[2].month}
										day={MeetList[2].day}
										year={MeetList[2].year}
										count={MeetList[2].count}
									/>
									<CardTitle
										background="#13C2C2"
										photo={UserList[0].photo}
										name={UserList[0].name}
										position={UserList[0].position}
									/>
									<CardMeet
										title={MeetList[3].title}
										desc={MeetList[3].desc}
										start={MeetList[3].start}
										stop={MeetList[3].stop}
										month={MeetList[3].month}
										day={MeetList[3].day}
										year={MeetList[3].year}
										count={MeetList[3].count}
									/>
								</Col>
								<Col span={6}>
									<CardTitleList
										background="#FAFAFA"
										list={true}
										tagcolor="#73D13D"
										day={MeetList[0].day}
										month={MeetList[0].month}
										year={MeetList[0].year}
										photo={UserList[0].photo}
										name={UserList[0].name}
									/>
									<CardTitleVideo
										title={MeetList[0].title}
										start={MeetList[0].start}
										stop={MeetList[0].stop}
										long={MeetList[0].long}
										video={MeetList[0].video}
										download={MeetList[0].download}
										background="#FAFAFA"
									/>
									<Card style={{ borderRadius: 0, borderColor: '1px solid #f4f4f4', borderTop: 'none', borderBottom: 'none' }}>
										<Timeline style={{ marginBottom: -42 }}>
											<Timeline.Item style={{ paddingTop: 0, paddingBottom: 8 }}>
												<Space>
													<Tag color={'#40A9FF'} style={{ fontWeight: 600, borderRadius: 4, marginLeft: -8 }}>{MeetList[0].line[0].label}</Tag>
													<span style={{ fontSize: 12, paddingTop: 4, marginLeft: -8, fontWeight: 400, color: '#262626', maxHeight: 20, overflow: 'hidden', display: 'inline-block' }}>{MeetList[0].line[0].subtitle}</span>
												</Space>
											</Timeline.Item>
											<Timeline.Item style={{ paddingTop: 0, paddingBottom: 8 }}>
												<Space>
													<Tag color={'#40A9FF'} style={{ fontWeight: 600, borderRadius: 4, marginLeft: -8 }}>{MeetList[0].line[1].label}</Tag>
													<span style={{ fontSize: 12, paddingTop: 4, marginLeft: -8, fontWeight: 400, color: '#262626', maxHeight: 20, overflow: 'hidden', display: 'inline-block' }}>{MeetList[0].line[1].subtitle}</span>
												</Space>
											</Timeline.Item>
											<Timeline.Item style={{ paddingTop: 0, paddingBottom: 8 }}>
												<Space>
													<Tag color={'#40A9FF'} style={{ fontWeight: 600, borderRadius: 4, marginLeft: -8 }}>{MeetList[0].line[2].label}</Tag>
													<span style={{ fontSize: 12, paddingTop: 4, marginLeft: -8, fontWeight: 400, color: '#262626', maxHeight: 20, overflow: 'hidden', display: 'inline-block' }}>{MeetList[0].line[2].subtitle}</span>
												</Space>
											</Timeline.Item>
											<Timeline.Item style={{ paddingTop: 0, paddingBottom: 8 }}>
												<Space>
													<Tag color={'#40A9FF'} style={{ fontWeight: 600, borderRadius: 4, marginLeft: -8 }}>{MeetList[0].line[3].label}</Tag>
													<span style={{ fontSize: 12, paddingTop: 4, marginLeft: -8, fontWeight: 400, color: '#262626', maxHeight: 20, overflow: 'hidden', display: 'inline-block' }}>{MeetList[0].line[3].subtitle}</span>
												</Space>
											</Timeline.Item>
											<Timeline.Item style={{ paddingTop: 0, paddingBottom: 8 }}>
												<Space>
													<Tag color={'#40A9FF'} style={{ fontWeight: 600, borderRadius: 4, marginLeft: -8 }}>{MeetList[0].line[4].label}</Tag>
													<span style={{ fontSize: 12, paddingTop: 4, marginLeft: -8, fontWeight: 400, color: '#262626', maxHeight: 20, overflow: 'hidden', display: 'inline-block' }}>{MeetList[0].line[4].subtitle}</span>
												</Space>
											</Timeline.Item>
											<Timeline.Item style={{ paddingTop: 0, paddingBottom: 8 }}>
												<Space>
													<Tag color={'#40A9FF'} style={{ fontWeight: 600, borderRadius: 4, marginLeft: -8 }}>{MeetList[0].line[5].label}</Tag>
													<span style={{ fontSize: 12, paddingTop: 4, marginLeft: -8, fontWeight: 400, color: '#262626', maxHeight: 20, overflow: 'hidden', display: 'inline-block' }}>{MeetList[0].line[5].subtitle}</span>
												</Space>
											</Timeline.Item>
											<Timeline.Item style={{ paddingTop: 0, paddingBottom: 8 }}>
												<Space>
													<Tag color={'#40A9FF'} style={{ fontWeight: 600, borderRadius: 4, marginLeft: -8 }}>{MeetList[0].line[6].label}</Tag>
													<span style={{ fontSize: 12, paddingTop: 4, marginLeft: -8, fontWeight: 400, color: '#262626', maxHeight: 20, overflow: 'hidden', display: 'inline-block' }}>{MeetList[0].line[6].subtitle}</span>
												</Space>
											</Timeline.Item>
											<Timeline.Item style={{ paddingTop: 0, paddingBottom: 8 }}>
												<Space>
													<Tag color={'#40A9FF'} style={{ fontWeight: 600, borderRadius: 4, marginLeft: -8 }}>{MeetList[0].line[7].label}</Tag>
													<span style={{ fontSize: 12, paddingTop: 4, marginLeft: -8, fontWeight: 400, color: '#262626', maxHeight: 20, overflow: 'hidden', display: 'inline-block' }}>{MeetList[0].line[7].subtitle}</span>
												</Space>
											</Timeline.Item>
										</Timeline>
									</Card>
									<CardTitleVideo
										title={MeetList[1].title}
										start={MeetList[1].start}
										stop={MeetList[1].stop}
										long={MeetList[1].long}
										video={MeetList[1].video}
										download={MeetList[1].download}
										background="#FAFAFA"
									/>
									<CardTitleVideo
										title={MeetList[2].title}
										start={MeetList[2].start}
										stop={MeetList[2].stop}
										long={MeetList[2].long}
										video={MeetList[2].video}
										download={MeetList[2].download}
										background="#FAFAFA"
									/>
									<CardTitleVideo
										title={MeetList[3].title}
										start={MeetList[3].start}
										stop={MeetList[3].stop}
										long={MeetList[3].long}
										video={MeetList[3].video}
										download={MeetList[3].download}
										background="#FAFAFA"
									/>
									<CardTitleList
										background="#FAFAFA"
										list={true}
										tagcolor="#73D13D"
										day={MeetList[0].day - 1}
										month={MeetList[0].month}
										year={MeetList[0].year}
										photo={UserList[0].photo}
										name={UserList[0].name}
									/>
									<CardTitleList
										background="#FAFAFA"
										list={true}
										tagcolor="#73D13D"
										day={MeetList[0].day - 2}
										month={MeetList[0].month}
										year={MeetList[0].year}
										photo={UserList[0].photo}
										name={UserList[0].name}
									/>
									<CardTitleList
										background="#FAFAFA"
										list={true}
										tagcolor="#73D13D"
										day={MeetList[0].day - 3}
										month={MeetList[0].month}
										year={MeetList[0].year}
										photo={UserList[0].photo}
										name={UserList[0].name}
									/>
								</Col>
								<Col span={7}>
									<Space
										direction={'vertical'}
										align="center"
										style={{ border: '1px solid #f4f4f4', backgroundColor: '#fafafa', padding: 0, borderRadius: 4 }}>
										<div style={{ color: '#262626', margin: '18px 0 10px', fontSize: 14, fontWeight: 600 }}>Select Date</div>
										<Calendar fullscreen={false} style={{ borderTop: '1px solid #f4f4f4', textAlign: 'center' }} />
									</Space>
								</Col>
								<Col span={4}>
									<CardTitleUser
										background="#FAFAFA"
										photo={UserList[0].photo}
										name={UserList[0].name}
									/>
									<CheckTime hour={HoursList[3].time} spots={HoursList[3].spot - HoursList[3].booked} />
									<CheckTime hour={HoursList[4].time} spots={HoursList[4].spot - HoursList[4].booked} />
									<CheckTime hour={HoursList[5].time} spots={HoursList[5].spot - HoursList[5].booked} />
									<CheckTime hour={HoursList[6].time} spots={HoursList[6].spot - HoursList[6].booked} />
									<CheckTime hour={HoursList[7].time} spots={HoursList[7].spot - HoursList[7].booked} />
									<Text style={{ fontWeight: 500, display: 'block', textAlign: 'center', padding: 16, borderBottom: '1px solid #f4f4f4' }}>{currentDate}</Text>
									<SelectMeet />
									<Text style={{ fontWeight: 500, display: 'block', textAlign: 'center', padding: 16, borderBottom: '1px solid #f4f4f4' }}>Topics</Text>
									<Topics />
									<Text style={{ fontWeight: 500, display: 'block', textAlign: 'center', padding: 16, borderBottom: '1px solid #f4f4f4' }}>Select Presenters</Text>
									<Avatars />
								</Col>
							</Row>
						</div>
					</Content>
					<Footer
						style={{
							textAlign: 'center'
						}}
						className="site-layout-background"
					>
						<small>Uny Space ©2022 Created by UNKD</small>
					</Footer>
				</Layout>
			</Layout>
		)
	}
}

export default App