const MassageList = [
	{
		name: 'Andrey Rogovsky',
		position: 'CEO',
		photo: 'https://ca.slack-edge.com/T020P9LH6KC-U021D3SHBT2-977eb4f5c47f-512',
		message: 'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary'
	},
	{
		name: 'Tonya Osipenko',
		position: 'Front & Design Lead',
		photo: 'https://ca.slack-edge.com/T020P9LH6KC-U02HMV7QBKK-b2f2647d6afb-512',
		message: 'Many desktop publishing packages and web page editors now use Lorem Ipsum'
	},
	{
		name: 'Natalia Kokorina',
		position: 'Lawyer',
		photo: 'https://ca.slack-edge.com/T020P9LH6KC-U030VAES03Y-612e5715a35b-512',
		message: 'Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia'
	},
	{
		name: 'Dmitriy Belka',
		position: 'SMM',
		photo: 'https://ca.slack-edge.com/T020P9LH6KC-U022XNP8N0Y-21c0eddd338b-512',
		message: ' English versions from the 1914 translation by H. Rackham'
	},
	{
		name: 'Sergey Soloviev',
		position: 'Backend Lead',
		photo: 'https://ca.slack-edge.com/T020P9LH6KC-U02DPGX119A-45ad971228af-512',
		message: 'It was popularised in the 1960s with the release of Letraset sheets containing'
	},
	{
		name: 'Yuriy Fedorov',
		position: 'BDM',
		photo: 'https://ca.slack-edge.com/T020P9LH6KC-U03BN96PH9N-51dfcfca144c-512',
		message: 'If you are going to use a passage of Lorem Ipsum, you need to be sure there'
	}
]

export default MassageList