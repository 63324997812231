import { Avatar } from 'antd'
import UserList from './UserList'

const Avatars = () => {

	return (
		<Avatar.Group
			maxCount={5}
			maxPopoverTrigger="click"
			size="large"
			style={{ margin: 16 }}
		>
			<Avatar src={UserList[0].photo} style={{ border: '2px solid #fff', boxShadow: '0 1px 10px #eee' }} />
			<Avatar src={UserList[1].photo} style={{ border: '2px solid #fff', boxShadow: '0 1px 10px #eee' }} />
			<Avatar src={UserList[2].photo} style={{ border: '2px solid #fff', boxShadow: '0 1px 10px #eee' }} />
			<Avatar src={UserList[3].photo} style={{ border: '2px solid #fff', boxShadow: '0 1px 10px #eee' }} />
			<Avatar src={UserList[4].photo} style={{ border: '2px solid #fff', boxShadow: '0 1px 10px #eee' }} />
		</Avatar.Group>
	)
}

export default Avatars