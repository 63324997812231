import React from 'react'
import { Button, Row, Col, Avatar } from 'antd';
import { RightOutlined } from '@ant-design/icons';

const CardTitle = (props) => {
	return (
		<Row
			justify="space-between"
			style={{ backgroundColor: props.background, padding: '10px 20px', borderRadius: '4px 4px 0 0' }}>
			<Col style={{ paddingTop: '2px' }}>
				<Row justify="start">
					<Col>
						<Avatar style={{ verticalAlign: 'middle' }} src={props.photo} size={40} />
					</Col>
					<Col style={{ paddingLeft: '8px' }}>
						<h4 style={{ marginBottom: '-4px', color: '#fff', fontWeight: 700 }}>{props.name}</h4>
						<span style={{ color: '#fff' }}>{props.position}</span>
					</Col>
				</Row>
			</Col>
			<Col style={{ padding: '5px 0' }}>
				<Button size={'small'} style={{ border: 'none', marginTop: 4 }} icon={<RightOutlined style={{ fontSize: 10 }} />} />
			</Col>
		</Row>
	)
}
export default CardTitle