import React, { useState } from 'react'
import { Row, Col, Button, Typography, Space } from 'antd'
import {
	PlayCircleFilled,
	YoutubeFilled,
	CloudDownloadOutlined,
	ClockCircleOutlined,
	VideoCameraOutlined,
	CaretUpFilled,
	CaretDownFilled
} from '@ant-design/icons'

const { Text } = Typography

const CardTitleList = (props) => {

	const [showCaret, setShowCaret] = useState('inline-block')
	const [hideCaret, setHideCaret] = useState('none')

	return (
		<Row
			justify="space-between"
			style={{ border: '1px solid #f4f4f4', borderTop: 'none', backgroundColor: props.background, padding: '4px 10px 0 14px' }}>
			<Col style={{ paddingTop: '2px' }}>
				<Row justify="start">
					<Button type="link" style={{ color: '#1890FF', padding: 0, fontSize: 32 }}><PlayCircleFilled /></Button>
					<Col style={{ padding: '4px 8px' }}>
						<h5 style={{ marginBottom: '-6px', color: '#262626', fontSize: 16, fontWeight: 700 }}>{props.title}</h5>
						<Space>
							<ClockCircleOutlined style={{ color: '#8C8C8C', fontSize: 10 }} />
							<Text style={{ color: '#8C8C8C', fontSize: 10, fontWeight: 500, marginLeft: -4 }}>{props.long}</Text>
							<VideoCameraOutlined style={{ color: '#8C8C8C', fontSize: 10 }} />
							<Text style={{ color: '#8C8C8C', fontSize: 10, fontWeight: 500, marginLeft: -4 }}>{props.start}-{props.stop}</Text>
						</Space>
					</Col>
				</Row>
			</Col>
			<Col style={{ padding: '10px 0' }}>
				<Button type="link" style={{ padding: '2px 4px 0', fontSize: 21, marginLeft: -20 }}><a href={props.video}><YoutubeFilled /></a></Button>
				<Button type="link" style={{ padding: '0 5px', fontSize: 20 }}><a href={props.download}><CloudDownloadOutlined /></a></Button>
				<Button
					type="link"
					onClick={() => {
						setShowCaret('none')
						setHideCaret('inline-block')
					}}
					style={{ display: showCaret, padding: '0 4px 4px', fontSize: 16, color: '#BFBFBF' }}
				>
					<CaretUpFilled />
				</Button>
				<Button
					type="link"
					onClick={() => {
						setShowCaret('inline-block')
						setHideCaret('none')
					}}
					style={{ display: hideCaret, padding: '0 4px 4px', fontSize: 16, color: '#BFBFBF' }}
				>
					<CaretDownFilled />
				</Button>
			</Col>
		</Row>
	)
}
export default CardTitleList