const HoursList = [
	{
		time: '10:00',
		spot: 100,
		booked: 18
	},
	{
		time: '11:00',
		spot: 100,
		booked: 100
	},
	{
		time: '12:00',
		spot: 100,
		booked: 100
	},
	{
		time: '13:00',
		spot: 100,
		booked: 88
	},
	{
		time: '14:00',
		spot: 100,
		booked: 100
	},
	{
		time: '15:00',
		spot: 100,
		booked: 100
	},
	{
		time: '16:00',
		spot: 100,
		booked: 100
	},
	{
		time: '17:00',
		spot: 100,
		booked: 100
	},
	{
		time: '18:00',
		spot: 100,
		booked: 42
	},
	{
		time: '19:00',
		spot: 100,
		booked: 100
	},
	{
		time: '20:00',
		spot: 100,
		booked: 94
	},
	{
		time: '21:00',
		spot: 100,
		booked: 100
	},
]

export default HoursList