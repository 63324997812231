import React, { useState } from 'react'
import { Row, Col, Avatar, Tag, Typography } from 'antd'
import { CalendarOutlined, CaretUpFilled, CaretDownFilled } from '@ant-design/icons'

const { Text } = Typography

const CardTitleList = (props) => {

	const [showAuthorList, setShowAuthorList] = useState('block')
	const [hideAuthorList, setHideAuthorList] = useState('none')

	return (
		<Row
			justify="space-between"
			style={{ border: '1px solid #f4f4f4', backgroundColor: props.background, padding: '16px 4px 12px 22px', borderRadius: '4px 4px 0 0' }}>
			<Col style={{ paddingTop: '2px' }}>
				<Row justify="start">
					<Col>
						<Tag color={props.tagcolor} style={{ borderRadius: 4 }}>
							<CalendarOutlined style={{ fontSize: 14 }} />
							<Text style={{ fontSize: 12, fontWeight: 600, color: '#fff', letterSpacing: '-0.05em' }}>{props.day}.{props.month}.{props.year}</Text>
						</Tag>
						<Avatar style={{ verticalAlign: 'middle' }} src={props.photo} size={20} />
					</Col>
					<Col style={{ padding: '3px 0 0 4px' }}>
						<h5 style={{ marginBottom: '-4px', color: '#262626', fontWeight: 500 }}>{props.name}</h5>
					</Col>
				</Row>
			</Col>
			<Col style={{ padding: '5px 0' }}>
				<Tag
					style={{ display: showAuthorList, padding: '0 4px', border: 'none', color: '#BFBFBF', fontSize: 14 }}
					onClick={() => {
						setShowAuthorList('none')
						setHideAuthorList('block')
					}}>
					<CaretUpFilled />
				</Tag>
				<Tag
					style={{ display: hideAuthorList, padding: '0 4px', border: 'none', color: '#BFBFBF', fontSize: 14 }}
					onClick={() => {
						setShowAuthorList('block')
						setHideAuthorList('none')
					}}>
					<CaretDownFilled />
				</Tag>
			</Col>
		</Row>
	)
}
export default CardTitleList