import React from 'react'
import { Avatar, Typography, Space } from 'antd'

const { Text } = Typography

const CardTitleList = (props) => {

	return (
		<Space
			direction={'vertical'}
			align="center"
			style={{
				width: '100%',
				border: '1px solid #f4f4f4',
				backgroundColor: props.background,
				padding: 8,
				borderRadius: 4
			}}>
			<Avatar style={{ verticalAlign: 'middle' }} src={props.photo} size={40} />
			<Text style={{ display: 'block', margin: '-2px auto', color: '#262626', fontWeight: 500 }}>{props.name}</Text>
		</Space>
	)
}
export default CardTitleList