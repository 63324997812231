import React, { useState } from 'react'
import { Button, Row, Col } from 'antd'

const CheckTime = (props) => {

	const [showTime, setShowTime] = useState('block')
	const [showConfirm, setShowConfirm] = useState('none')

	return (
		<>
			<Button
				type="primary"
				ghost
				block
				onClick={() => {
					setShowTime('none')
					setShowConfirm('block')
				}}
				style={{ marginTop: 8, minHeight: 50, boxShadow: 'none', display: showTime }}
			>
				<div style={{ fontSize: 16, fontWeight: 700, margin: '0 0 -8px' }}>{props.hour}</div><span>{props.spots} spots left</span>
			</Button>
			<div style={{ display: showConfirm }} >
				<Row>
					<Col span={12} style={{ paddingRight: 4 }}>
						<Button block style={{ border: 'none', marginTop: 8, minHeight: 50, boxShadow: 'none', backgroundColor: '#8c8c8c' }}>
							<div style={{ fontSize: 14, fontWeight: 600, color: '#fff' }}>{props.hour}</div>
						</Button>
					</Col>
					<Col span={12} style={{ paddingLeft: 4 }}>
						<Button
							type="primary"
							block
							onClick={() => {
								setShowConfirm('none')
								setShowTime('block')
							}}
							style={{ border: 'none', marginTop: 8, minHeight: 50, boxShadow: 'none' }}
						>
							<div style={{ fontSize: 14, fontWeight: 600, color: '#fff' }}>Confirm</div>
						</Button>
					</Col>
				</Row>
			</div>
		</>
	)
}
export default CheckTime