import React, { useState, useRef } from 'react'
import { Tag, Input } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

const Topics = () => {
	const [tags, setTags] = useState(['Carrd', 'Weblium'])
	const [inputVisible, setInputVisible] = useState(false)
	const [inputValue, setInputValue] = useState('')
	const inputRef = useRef(null)

	const handleClose = (removedTag) => {
		const newTags = tags.filter((tag) => tag !== removedTag)
		console.log(newTags)
		setTags(newTags)
	}

	const showInput = () => {
		setInputVisible(true)
	}

	const handleInputChange = (e) => {
		setInputValue(e.target.value)
	}

	const handleInputConfirm = () => {
		if (inputValue && tags.indexOf(inputValue) === -1) {
			setTags([...tags, inputValue])
		}

		setInputVisible(false)
		setInputValue('')
	}

	const forMap = (tag) => {
		const tagElem = (
			<Tag
				closable
				onClose={(e) => {
					e.preventDefault()
					handleClose(tag)
				}}
			>
				{tag}
			</Tag>
		)
		return (
			<span
				key={tag}
				style={{
					display: 'inline-block',
				}}
			>
				{tagElem}
			</span>
		)
	}

	const tagChild = tags.map(forMap)
	return (
		<div style={{ marginTop: 16, marginBottom: 16 }}>
			{tagChild}
			<div style={{ paddingTop: 16, display: 'inline-block' }}>
				{inputVisible && (
					<Input
						ref={inputRef}
						type="text"
						size="small"
						style={{
							width: 78,
						}}
						value={inputValue}
						onChange={handleInputChange}
						onBlur={handleInputConfirm}
						onPressEnter={handleInputConfirm}
					/>
				)}
				{!inputVisible && (
					<Tag onClick={showInput} className="site-tag-plus">
						<PlusOutlined />
					</Tag>
				)}
			</div>
		</div>
	)
}

export default Topics