const UserList = [
	{
		name: 'Andrey Rogovsky',
		position: 'CEO',
		photo: 'https://ca.slack-edge.com/T020P9LH6KC-U021D3SHBT2-977eb4f5c47f-512'
	},
	{
		name: 'Tonya Osipenko',
		position: 'Front & Design Lead',
		photo: 'https://ca.slack-edge.com/T020P9LH6KC-U02HMV7QBKK-b2f2647d6afb-512'
	},
	{
		name: 'Dmitriy Belka',
		position: 'SMM',
		photo: 'https://ca.slack-edge.com/T020P9LH6KC-U022XNP8N0Y-21c0eddd338b-512'
	},
	{
		name: 'Sergey Soloviev',
		position: 'Backend Lead',
		photo: 'https://ca.slack-edge.com/T020P9LH6KC-U02DPGX119A-45ad971228af-512'
	},
	{
		name: 'Yuriy Fedorov',
		position: 'BDM',
		photo: 'https://ca.slack-edge.com/T020P9LH6KC-U03BN96PH9N-51dfcfca144c-512'
	}
]

export default UserList