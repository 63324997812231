const MeetList = [
	{
		title: 'Sync-Up',
		desc: 'Tell your blockers, Meet new people',
		start: '20:00',
		stop: '22:00',
		day: '05',
		month: '06',
		year: '2022',
		long: '24:15',
		video: 'youtube.com/dcYc8e87j',
		download: '/',
		count: 56,
		line: [
			{
				id: 0,
				label: '02:05',
				subtitle: 'Greetings'
			},
			{
				id: 2,
				label: '04:31',
				subtitle: 'Discussion of the Advogram'
			},
			{
				id: 3,
				label: '07:14',
				subtitle: 'Discussion of the UNKD project'
			},
			{
				id: 4,
				label: '09:12',
				subtitle: 'Case discussion'
			},
			{
				id: 5,
				label: '10:32',
				subtitle: 'Grants discussion'
			},
			{
				id: 6,
				label: '12:44',
				subtitle: 'Meet with new people'
			},
			{
				id: 7,
				label: '14:15',
				subtitle: 'Backend work'
			},
			{
				id: 8,
				label: '15:55',
				subtitle: 'Conclusion'
			}
		]
	},
	{
		title: 'Demo',
		desc: 'Show te Demo (if you have), Meet with new people',
		start: '18:00',
		stop: '19:00',
		day: '05',
		month: '06',
		year: '2022',
		long: '48:55',
		video: 'youtube.com/dcYc8e87j',
		download: '/',
		count: 37,
		line: [
			{
				id: 0,
				label: '02:05',
				subtitle: 'Greetings'
			},
			{
				id: 2,
				label: '04:31',
				subtitle: 'Discussion of the Advogram'
			},
			{
				id: 3,
				label: '07:14',
				subtitle: 'Discussion of the UNKD project'
			},
			{
				id: 4,
				label: '09:12',
				subtitle: 'Case discussion'
			},
			{
				id: 5,
				label: '10:32',
				subtitle: 'Grants discussion'
			},
			{
				id: 6,
				label: '12:44',
				subtitle: 'Meet with new people'
			},
			{
				id: 7,
				label: '14:15',
				subtitle: 'Backend work'
			},
			{
				id: 8,
				label: '15:55',
				subtitle: 'Conclusion'
			}
		]
	},
	{
		title: 'Discovery',
		desc: 'Базовые вещи, который должен знать, уметь и использовать каждый, кто находиться в нашей команде - без исключений!, Если вы что-то не знаете, не страшно - поможем :), Записи сохраняются',
		start: '13:00',
		stop: '14:00',
		day: '05',
		month: '06',
		year: '2022',
		long: '35:08',
		video: 'youtube.com/dcYc8e87j',
		download: '/',
		count: 8,
		line: [
			{
				id: 0,
				label: '02:05',
				subtitle: 'Greetings'
			},
			{
				id: 2,
				label: '04:31',
				subtitle: 'Discussion of the Advogram'
			},
			{
				id: 3,
				label: '07:14',
				subtitle: 'Discussion of the UNKD project'
			},
			{
				id: 4,
				label: '09:12',
				subtitle: 'Case discussion'
			},
			{
				id: 5,
				label: '10:32',
				subtitle: 'Grants discussion'
			},
			{
				id: 6,
				label: '12:44',
				subtitle: 'Meet with new people'
			},
			{
				id: 7,
				label: '14:15',
				subtitle: 'Backend work'
			},
			{
				id: 8,
				label: '15:55',
				subtitle: 'Conclusion'
			}
		]
	},
	{
		title: 'DevOps',
		desc: 'DevOps Кейсы',
		start: '20:00',
		stop: '22:00',
		day: '05',
		month: '06',
		year: '2022',
		long: '1:18:46',
		video: 'youtube.com/dcYc8e87j',
		download: '/',
		count: 6,
		line: [
			{
				id: 0,
				label: '02:05',
				subtitle: 'Greetings'
			},
			{
				id: 2,
				label: '04:31',
				subtitle: 'Discussion of the Advogram'
			},
			{
				id: 3,
				label: '07:14',
				subtitle: 'Discussion of the UNKD project'
			},
			{
				id: 4,
				label: '09:12',
				subtitle: 'Case discussion'
			},
			{
				id: 5,
				label: '10:32',
				subtitle: 'Grants discussion'
			},
			{
				id: 6,
				label: '12:44',
				subtitle: 'Meet with new people'
			},
			{
				id: 7,
				label: '14:15',
				subtitle: 'Backend work'
			},
			{
				id: 8,
				label: '15:55',
				subtitle: 'Conclusion'
			}
		]
	}
]

export default MeetList