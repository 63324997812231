import React, { useState } from 'react'
import { Radio, Input, Space } from 'antd'

const SelectMeet = () => {
	const [value, setValue] = useState(1)

	const onChange = (e) => {
		console.log('radio checked', e.target.value)
		setValue(e.target.value)
	}

	return (
		<Radio.Group onChange={onChange} value={value}>
			<Space direction="vertical" style={{ padding: '16px 0' }}>
				<Radio value={0}>Sync-Up</Radio>
				<Radio value={1}>Discovery</Radio>
				<Radio value={2}>Demo</Radio>
				<Radio value={3}>DevOps</Radio>
				<Radio value={4}>
					+
          {value === 4 ? (
						<Input
							style={{
								width: 100,
								marginLeft: 10,
							}}
						/>
					) : null}
				</Radio>
			</Space>
		</Radio.Group>
	)
}

export default SelectMeet